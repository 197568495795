import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "630px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/c3559246acdce59fbf4f35e08eab0794/828fb/emi26b.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "51%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwACBP/EABUBAQEAAAAAAAAAAAAAAAAAAAID/9oADAMBAAIQAxAAAAFS0dR2RwX/xAAaEAACAwEBAAAAAAAAAAAAAAACAwABEhEE/9oACAEBAAEFAjferdc8zuLIR1kYoR5//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAAAH/2gAIAQIBAT8BV//EABwQAAICAgMAAAAAAAAAAAAAAAABISICEjEyUf/aAAgBAQAGPwJyRkPb0dUdUOq5P//EABwQAAMAAQUAAAAAAAAAAAAAAAABESFBUWGx4f/aAAgBAQABPyHA5VG1kpANXuOdj0H5B0oH/9oADAMBAAIAAwAAABB07//EABcRAQEBAQAAAAAAAAAAAAAAABEBACH/2gAIAQMBAT8QKLpyb//EABYRAQEBAAAAAAAAAAAAAAAAAAERAP/aAAgBAgEBPxASyYVXf//EABwQAQADAAIDAAAAAAAAAAAAAAEAIVERYZGxwf/aAAgBAQABPxDgyEuATzcWCUdQmUXbuwfseirl2HaGsz15cJ//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Emagic EMI 2|6",
                "title": "Emagic EMI 2|6",
                "src": "/static/c3559246acdce59fbf4f35e08eab0794/828fb/emi26b.jpg",
                "srcSet": ["/static/c3559246acdce59fbf4f35e08eab0794/f93b5/emi26b.jpg 300w", "/static/c3559246acdce59fbf4f35e08eab0794/b4294/emi26b.jpg 600w", "/static/c3559246acdce59fbf4f35e08eab0794/828fb/emi26b.jpg 630w"],
                "sizes": "(max-width: 630px) 100vw, 630px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Description
Introduced at NAMM in January 2000, EMI 2|6 was the first USB-based multi-channel
audio interface. EMI in conjunction with Logic Audio formed a tightly integrated
system: an affordable one-stop solution for the traveling musician. Mobility was
the key aspect here: laptop, EMI, software, go!`}</p>
        <p>{`At the time the project was started, no suitable solutions existed to build a USB
audio interface featuring more than two channels of audio and external synchronization.
Therefore, the solution was customized with Cypress‘ EZusb chip to interface with USB
plus an FPGA implementing the complete audio data path. In addition to the hardware
development, we had to teach the major OS vendors how  to implement external
synchronization with USB, as no other device did that before. As before with Audiowerk,
the concept was good enough to be closely copied by big brands in the MI market.`}</p>
        <p>{`Between 2001 and 2003, more than 20.000 units of EMI have been sold, again a very
welcome success for Emagic. Customers reached beyond musicians: DJs, home theatre
users, multi-media presenters and all those users looking for a mobile audio solution.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "675px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/80272ecc986396857294bbaf7596ecd0/23296/emi26_sf.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "80.66666666666666%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7UlEQVQoz31SXXOcMAzk//+9NC9pJnfHccEcB/62JFvYGdCk0zZp94lBkrW72q7W2r7Dt/9rrcaYEIJz7n6/d1/K7T/IOYcQcs5EhABdTEn6C/OvbaXkS38tzH9RyDmPSllrEaDW2vV9H2MkohBCSoBErTUieh/HfGDbts9q8t5dh2EclTZGTVN3Pp0RkYi0OWDtOI7z/EgpEZG1VhSGGLz3zjlmzjkDgHOum+dZXo0xHq+gc25d13mejTHLunjvpcEdYGYiWpZFqal7fX1Fopzz6XS21vKhcxhub28nRPzx/Ky1kZ0pJUQsB5yzb6dzp7XOOSPiMLwbY0oprTXEfb/QPkq3y+W68SacmRkRRqX+OBUiKqXqgVIKEcl3jNFaCwDyNDNbax/L0iERALbWjNG323vfXwHgOgxx99YDABE557z3wmLXjGitmx+PLsR0Op+NtdM0Lcsih621Mm/OOa01M8cYxV5ErLWKnH34iERBxG3bfpcgM09Pzz4EAAgHiKiUknOWi3T/SmJhBgDvfSlFJGitU0qHW6i1nu73Tkh+HWbmdV3jkWTvvTAXw/ZTeb8bNgyDnF4OyMfCYbhKeF5efqYEKSUAuN1uxhhpcM4ty9KllCTAAvFDKaW1BoBL34tJrbU9c9btjn6y+wDc65seH2A+iwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Emagic EMI 2|6 Signal Flow Diagram",
                "title": "Emagic EMI 2|6 Signal Flow Diagram",
                "src": "/static/80272ecc986396857294bbaf7596ecd0/23296/emi26_sf.png",
                "srcSet": ["/static/80272ecc986396857294bbaf7596ecd0/5a46d/emi26_sf.png 300w", "/static/80272ecc986396857294bbaf7596ecd0/0a47e/emi26_sf.png 600w", "/static/80272ecc986396857294bbaf7596ecd0/23296/emi26_sf.png 675w"],
                "sizes": "(max-width: 675px) 100vw, 675px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p>{`Features`}</p>
        <ul>
          <li parentName="ul">{`2 analog inputs (RCA)`}</li>
          <li parentName="ul">{`6 analog outputs (RCA)`}</li>
          <li parentName="ul">{`8 signal present LEDs`}</li>
          <li parentName="ul">{`headphone output (1/8“ jack)`}</li>
          <li parentName="ul">{`potentiometer to control headphone volume`}</li>
          <li parentName="ul">{`24 bit converter resolution with 100dB dynamic range`}</li>
          <li parentName="ul">{`1 digital input (RCA)`}</li>
          <li parentName="ul">{`1 digital output (RCA)`}</li>
          <li parentName="ul">{`sample rate 44.1kHz, 48kHz`}</li>
          <li parentName="ul">{`internal or external synchronization`}</li>
          <li parentName="ul">{`mechanical switch to select clock source (internal/ external)`}</li>
          <li parentName="ul">{`mechanical  switch to select input (analog/ digital)`}</li>
          <li parentName="ul">{`USB 1.1 (12Mbps) interface, bus-powered`}</li>
          <li parentName="ul">{`drivers for Windows 9x/ Millennium/ 2k/ XP, Mac OS 9, Mac OS X`}</li>
        </ul>
        <p>{`As a spin-off the EMI 6|2m was created. Main differences: 6 analog inputs, 2
analog outputs. Digital ports configurable as either SPDIF or MIDI I/O. Built-in
USB hub. 8-in-4 mixer to create separate mixes for main outputs and phones. By
moving even more functionality into the FPGA, the additional features could be
implemented at roughly the same cost.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "622px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/15bb5280ca672b4fc3196dea01cc1ba2/604ec/emi62_sf.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "87.66666666666667%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAAAsTAAALEwEAmpwYAAACL0lEQVQ4y3WTbY6jMAyGc//T9Ai9QPuvmtWWZWah/GihZSgkAQp49bh1hUZaS5ad+PuN45Zl8cbDMPgQgrKI+Hme/Ck/6XkcR7XXde2LovBQ13V6j6/lcFVVyfV6ldvtJm3bStPcVYcej4fsd3spy0piH2VZFvn79SW73V6+v7817nw+S9M0qhPvYowLPAyDymmalrZtFxFRRp+naen7Xs/e+6VpGvULIagdndhxHBdHN3Bd19J1nYzjqBWNhmHQzmJ8dhhCUB2mI2KtW+tQjX3fvyVBBN/vd9Uh7iFLyBk7DRgR4+gMpisq4EynSKpi64dBbSFE8d6/9KASnKdpUoZciFGN61HQCYQZiQJlWSozHsUphN88z5JlmaTp5zMhRpggJKPAJGMEG8lGpiCEv/kQZ9A4cIAxUp1HMDzXCbizR4GIwUYyVg87xZ1VMkwsoT0MZ3Cyl8eXMTlzTwzj4wuOzjDBwCOssbSRsV8uF02Ijo0kMT5fGttms5Htditu/SDrl7aEtiLWNYxOwrXv4XCQNE3FyYroFoNhYh1ackZCMnaeZfLx8Uvi67GMnO0ckpGTJJHyUipOkP1xO9tyf6ap/D4eX6M/9xPpbKlxAo8k+aNnOqFjnAx8W2CSF0Uhx2Py3lvykNStvw2J8jyXun6uD+fb7SpZlqszMHBPgtMp12X2rzV6jwxedOKDV2cwQ1rgtaq0m5+BP4mG9C+DHYEk4oJxYAPfFha/deD/+B+Jcm/1+oWnMAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Emagic EMI 6|2m Signal Flow Diagram",
                "title": "Emagic EMI 6|2m Signal Flow Diagram",
                "src": "/static/15bb5280ca672b4fc3196dea01cc1ba2/604ec/emi62_sf.png",
                "srcSet": ["/static/15bb5280ca672b4fc3196dea01cc1ba2/5a46d/emi62_sf.png 300w", "/static/15bb5280ca672b4fc3196dea01cc1ba2/0a47e/emi62_sf.png 600w", "/static/15bb5280ca672b4fc3196dea01cc1ba2/604ec/emi62_sf.png 622w"],
                "sizes": "(max-width: 622px) 100vw, 622px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </Main>
      <Aside mdxType="Aside" />
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      